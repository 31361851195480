import {
  HandleError,
  HttpErrorHandler,
} from './../../../util/http-error-handler.service';
import { environment } from './../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
const API_URL = environment.apiUrl;
const API_BASE_URL = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private handleError: HandleError;
  constructor(private http: HttpClient, httpError: HttpErrorHandler) {
    this.handleError = httpError.createHandleError('DashboardService');
  }

  obterPieCharts(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/pieCharts')
      .pipe(catchError(this.handleError('pieCharts', [])));
  }
  obterNovoClientesPorMes(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalNewClientMonth')
      .pipe(catchError(this.handleError('totalNewClientMonth', [])));
  }
  obterPontuacoesPorDiaDoMes(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalScoreByDayOfMonth')
      .pipe(catchError(this.handleError('totalScoreByDayOfMonth', [])));
  }
  obterPontuacoesPorAno(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalScoreByYearOfMonth')
      .pipe(catchError(this.handleError('totalScoreByYearOfMonth', [])));
  }
  obterComparacoesDePontuacoePorAno(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalScoreCompareYear')
      .pipe(catchError(this.handleError('totalScoreCompareYear', [])));
  }

  obterBestcustomers(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/bestcustomers')
      .pipe(catchError(this.handleError('bestcustomers', [])));
  }

  obterComparacaoDeEmperesasCadastradaPorAno(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalRegisterCompareYear')
      .pipe(catchError(this.handleError('totalRegisterCompareYear', [])));
  }

  obterIndicadores(_param: any): Observable<any> {
    let params;
    if (_param != null) {
      params = new HttpParams()
        .set('CodigoEmpresa', `${_param.codigoEmpresa}`)
        .set('DataInicio', `${(_param.dataInicio ? _param.dataInicio.toJSON() : null)}`)
        .set('DataFinal', `${(_param.dataFim ? _param.dataFim.toJSON() : null)}`);
    }
    return this.http.get<any>(API_URL + 'dashboard/indicadores', { params })
      .pipe(catchError(this.handleError('obterIndicadores', []))
      );
  }

  obterTotalCliente(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalcliente')
      .pipe(catchError(this.handleError('totalcliente', [])));
  }

  obterTotalClienteRecorrente(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalclientrecorrente')
      .pipe(catchError(this.handleError('totalclientrecorrente', [])));
  }

  obterTotalClienteNaoRecorrente(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalClientenaorecorrente')
      .pipe(catchError(this.handleError('totalClientenaorecorrente', [])));
  }

  obterTotalPontuacao(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalpontuacao')
      .pipe(catchError(this.handleError('totalpontuacao', [])));
  }

  obterTotalCartaoIncompleto(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalcartaoincompleto')
      .pipe(catchError(this.handleError('totalcartaoincompleto', [])));
  }

  obterProximosABonitificar(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totaproximobonificar')
      .pipe(catchError(this.handleError('totaproximobonificar', [])));
  }

  obterTotalCartao(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totacartao')
      .pipe(catchError(this.handleError('totacartao', [])));
  }

  obterTotalCartaoCompleto(): Observable<any> {
    return this.http.get<any>(API_URL + 'analytics/totalcartaocompleto')
      .pipe(catchError(this.handleError('totalcartaocompleto', [])));
  }


  obterFaturamentoAnual(): Observable<any> {
    return this.http.get<any>(API_BASE_URL + '/v1/faturamento/anual')
      .pipe(catchError(this.handleError('faturamentoAnual', [])));
  }

  obterFaturamentoMes(): Observable<any> {
    return this.http.get<any>(API_BASE_URL + '/v1/faturamento/mes')
      .pipe(catchError(this.handleError('faturamentomes', [])));
  }

  obterFaturamentoDia(): Observable<any> {
    return this.http.get<any>(API_BASE_URL + '/v1/faturamento/dia')
      .pipe(catchError(this.handleError('faturamentodia', [])));
  }

  obterFaturamentoTicketMedioMes(): Observable<any> {
    return this.http.get<any>(API_BASE_URL + '/v1/faturamento/ticketmedioano')
      .pipe(catchError(this.handleError('ticketmedioano', [])));
  }


}
