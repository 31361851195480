
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { SettingsService } from './settings/settings.service';
import { ThemesService } from './themes/themes.service';
import { TranslatorService } from './translator/translator.service';
import { MenuService } from './menu/menu.service';
import { AuthorizationService } from '../routes/authorization/authorization.service';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { HttpErrorHandler } from '../util/http-error-handler.service';
import { MessageService } from '../routes/messages/message.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { V1HttpInterceptor } from '../util/V1HttpInterceptor';
import { NgxSelectModule } from 'ngx-select-ex';


import { V1AuthGuard } from '../util/V1AuthGuard';
import { LoadingService } from '../util/loader.service';


import { DashboardService } from '../routes/fidel/dashboard/dashboard.service';


@NgModule({
  imports: [NgxSelectModule, NgxMaskModule.forRoot()],
  providers: [
    SettingsService,
    ThemesService,
    TranslatorService,
    MenuService,
    HttpErrorHandler,
    MessageService,
    AuthorizationService,
    { provide: HTTP_INTERCEPTORS, useClass: V1HttpInterceptor, multi: true },
    V1AuthGuard,
    LoadingService,
    DashboardService
  ],
  declarations: [],
  exports: [NgxSelectModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
