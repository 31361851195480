import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex'
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
//import { MapaLocalizacaoComponent } from '../fidel/mapaLocalizacao/mapaLocalizacao.component';
import { AgmCoreModule } from '@agm/core';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* Use this routes definition in case you want to make them lazy-loaded */
/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/

@NgModule({
  imports: [
    SharedModule,
    NgxSelectModule,
    NgxMaskModule.forRoot(),
    NoopAnimationsModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNs42Rt_CyxAqdbIBK0a5Ut83QiauESPA'
    })
    // RouterModule.forChild(routes)
  ],
  declarations: [
    LoginComponent,
  ],
  exports: [
    RouterModule,
    NgxSelectModule,
    LoginComponent,
    NoopAnimationsModule,
    BrowserAnimationsModule,

  ]
})
export class PagesModule { }
