import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { AuthorizationService } from '../../authorization/authorization.service';
import { AuthorizationData } from '../../../models/usuario/authorizationData';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../util/loader.service';

const swal = require('sweetalert');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  valForm: FormGroup;
  esqueciSenha: boolean = false;
  email: string;
  constructor(public settings: SettingsService, fb: FormBuilder, public service: AuthorizationService, private route: ActivatedRoute, private router: Router, public loader: LoadingService) {



    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'password': [null, Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      console.log('Valid!');
      console.log(value);
      this.loader.show("#body");
      this.service.LoginBackOffice(value.email, value.password)
        .subscribe(result => {

          this.loader.hide("#body");

          var authorizationData = new AuthorizationData();
          authorizationData.Token = result.token;
          if (result.usuario.nome != undefined) {
            authorizationData.Nome = result.usuario.nome;
          }
          if (result.usuario.email != undefined) {
            authorizationData.Email = result.usuario.email;
          }

          this.service.SaveLocalAuthorizationData(authorizationData);
          this.router.navigate(['/fidel/inicio']);

          //this.router.navigate([this.returnUrl]);
        },
          error => {

          });

      /*  this.service.LoginBackOffice(value.email, value.password)
            .subscribe(function (result) {
                if(result.success){
                   var authorizationData = result.data as AuthorizationData;
                   localStorage.setItem('authorizationData', JSON.stringify(authorizationData));

                }
            });*/
    }
  }

  ngOnInit() {

  }

  recuperarSenha() {
    this.esqueciSenha = true;
  }

  voltar() {
    this.esqueciSenha = false;
  }

  enviarEmail() {
    console.log(this.valForm.controls['email'].value);

    this.service.RecuperaEmail(this.valForm.controls['email'].value)
      .subscribe(result => {
        if (result.success) {
          swal('Atenção', 'Senha enviada por e-mail', 'success');
          this.voltar();
        }
      });
  }
}
