// #####################
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// #####################

// #####################
// Ajuda para publicação com package.json:
// Desenvolvimento Local      > npm run build       >>  environment.ts
// Publicação Desenvolvimento > npm run build:dev   >>  environment.dev.ts
// Publicação Pré-produção    > npm run build:stag  >>  environment.stag.ts
// Publicação Produção        > npm run build:prod  >>  environment.prod.ts
// #####################

export const environment = {
  name: 'localhost',
  production: true,
  //apiUrl: 'https://dev-api-empresa-backoffice.fidel.com.br/api/',
  //apiBaseUrl: 'https://dev-api-v2.fidel.com.br/api/',
  //apiUrl: 'http://localhost:4000/api/',
 // apiBaseUrl: 'http://localhost:4001/api/',
  //apiUrl: 'http://localhost:4001/api/',
 apiUrl: 'https://api-empresa-backoffice.fidel.com.br/api/',
  apiBaseUrl: 'https://api-v2.fidel.com.br/api/',
  mapbox: {
    accessToken: 'pk.eyJ1IjoibGlvbmFuZGFudGFzIiwiYSI6ImNrNWNnODE1OTAzd28zb3BsZTZ0Y3k4ODgifQ.868dhyDZ4pClscb2wBwlCg'
  }

};
