import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders, HttpErrorResponse }
  from '@angular/common/http';
const swal = require('sweetalert');
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { AuthorizationService } from '../routes/authorization/authorization.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { map, catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class V1HttpInterceptor implements HttpInterceptor {
  constructor(public authorizationService: AuthorizationService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: any;
    let dataAuth = this.authorizationService.GetLocalAuthorizationData();
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Origin': '*'
    });
    if (dataAuth != null && dataAuth != undefined && dataAuth.Token != null) {
      headers = headers.append("Authorization", 'Bearer ' +dataAuth.Token);
      /*headers = new HttpHeaders({
        'Authorization': 'Bearer ' + dataAuth.tokenInfo.accessToken,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'ocp-apim-subscription-key': environment.token
      });*/
    }
    // headers.lazyInit();
    const cloneReq = request.clone({ headers });

    return next.handle(cloneReq).pipe(map(event => {
      if (event instanceof HttpResponse) {

        console.log('---> status:', event.status);
        // console.log('---> filter:', req.params.get('filter'));
        switch (event.status) {

          case 201 || 200: {
            if (event.body.Success != undefined && !event.body.Success) {
              swal("Atenção", event.body.Message, 'warning');
            }
            break;
          }

          case 401 || 403: {
            swal("Atenção", "Sua Sessão Expirou. Sua sessão ficou inativa por muito tempo e foi encerrada. Efetue o Login novamente.", 'warning');
            setTimeout(() => {
              this.authorizationService.LogoutBackOffice();
              this.router.navigate(['/login']);
            }, 2000);
            break;
          }
          case 500: {
            swal("Atenção", "Houve uma falha em nosso servidor. Por favor, aguarde alguns instantes e tente novamente. Se o problema persistir, entre em contato com o administrador.", 'warning');
            break;
          }
          default: {
            break;
          }
        }



      } else if (event instanceof HttpErrorResponse) {
        console.log('---> status:', event.status);
      }

      return event;
    }), catchError(err => {
      debugger;
      if (err.status === 400) {
        swal("Atenção",err.error.error, 'warning');
      }
      else if (err.status === 401) {
        swal("Atenção", "Sua Sessão Expirou. Sua sessão ficou inativa por muito tempo e foi encerrada. Efetue o Login novamente.", 'warning');
        setTimeout(() => {
          this.authorizationService.LogoutBackOffice();
          this.router.navigate(['/login']);
        }, 2000);
      } else if (err.status === 500) {
        swal("Atenção", "Houve uma falha em nosso servidor. Por favor, aguarde alguns instantes e tente novamente. Se o problema persistir, entre em contato com o administrador.", 'warning');
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }),
      finalize(() => {
      })
    )
  }
}
