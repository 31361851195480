import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class DocApiServiceService {

  constructor(private http: HttpClient) {

  }

  public getJSON(): Observable<any> {
    return this.http.get('https://api-integracao.fidel.com.br/api-docs.json');
  }
}
