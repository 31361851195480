import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { V1AuthGuard } from '../util/V1AuthGuard';
import { DocApiComponent } from './fidel/doc-api/doc-api.component';


export const routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'fidel/inicio',
        pathMatch: 'full',
        canActivate: [V1AuthGuard]
      },
      /*{
        path: 'home',
        loadChildren: './home/home.module#HomeModule',
        canActivate: [V1AuthGuard]
      },*/
      /* { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },*/

      {
        path: 'fidel',
        loadChildren: './fidel/fidel.module#FidelModule',
        canActivate: [V1AuthGuard]
      },

    ]
  },

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },
  { path: 'doc-api', component: DocApiComponent },
  // { path: 'mapalocalizacao', component: MapaLocalizacaoComponent },

  // Not found
  { path: '**', redirectTo: 'inicio' }
];

